<template>
  <div>
    <b-container fluid class="mt-7">
      <div>
        <b-card
          class="col-lg-8 col-sm-12"
        >
          <b-row>
            <b-col class="col-8">
              <h1>Cartes</h1>
              <p>Liste des cartes</p>
            </b-col>
            <b-col class="col-4 text-right" align-h="end">
              <b-row align-h="end">
                <b-link @click="triggerAddMap()">
                  <i class="fas fa-plus"></i> Ajouter
                </b-link>
              </b-row>
              <b-row align-h="end">
                  <b-form-checkbox v-model="showAction" name="check-button" @change="showActionColumn()" size="sm" switch>Edit</b-form-checkbox>
                </b-row>

            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Recherche..."
            ></b-form-input>
            </b-col>
          </b-row>
          <b-table
            :items="maps"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            :busy="isBusy"
            ref="mapTable"
            responsive
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-link @click="triggerEditMap(data.item)"><b-icon icon="pencil"></b-icon></b-link>
              <b-link @click="triggerDeleteMap(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
            </template>

          </b-table>
        </b-card>

      </div>
    </b-container>
    <b-modal
      id="modalMapForm"
      :title="formTitle"
      ref="map-form-modal"
      v-model="modalShow"
      @hidden="modalHiddenAction"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm()"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputReference">Reference</label>
            <b-form-input id="inputReference" class="form-control" v-model="map.reference"></b-form-input>
          </b-col>
          <b-col>
            <label for="input-name">Nom</label>
            <b-form-input id="input-name" class="form-control" v-model="map.name"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-checkbox
              id="input-inStock"
              v-model="map.inStock"
              name="checkbox-1"
            >
              En stock
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalMapForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">{{ mapFormButtonSubmitName }}</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Maps',
  data() {
    return {
      fields: [
        {key: 'reference', label:'Reference', sortable: true},
        {key: 'name', label:'Nom', sortable: true},
        {
          key: 'cus_inStock',
          label:'En stock',
          sortable: false,
          formatter: (value, key, item) => {
            return item.inStock ? 'oui' : 'non';
          },
        }
      ],
      actionsField: [{key:'actions', label:'', Sortable: false}],
      sortBy: 'reference',
      sortDesc: false,
      editEnable: false,
      isBusy: true,
      showAction: false,
      filter:null,
      filterOn:[
        'reference',
        'name'
      ],
      map:{
        reference:'',
        name:'',
        inStock:false
      },
      modalShow:false,
      editMapId:0
    };
  },
  mounted() {
    this.loadMaps().then(()=>{
      this.isBusy = false;
    });
  },
  computed: {
    ...mapGetters({
      maps:'maps/maps',
    }),
    formTitle () {
      return this.editEnable ? 'Modification d\'une carte' : 'Création d\'une carte' ;
    },
    mapFormButtonSubmitName () {
      return this.editEnable ? 'Mettre à jour' : 'Créer';
    },
    token_viewer () {
      return JSON.parse(localStorage.getItem('user')).token;
    }
  },
  methods: {
    ...mapActions({
      loadMaps:'maps/loadMaps',
      addMap:'maps/addMap',
      deleteMap:'maps/deleteMap',
      updateMap:'maps/updateMap'
    }),
    showActionColumn: function () {
      this.$nextTick(function () {
        this.showAction ? this.fields.push(this.actionsField) : this.fields.pop();
      })
    },
    triggerEditMap: function (map) {
      this.editEnable = true;
      this.map.reference = map.reference;
      this.map.name = map.name;
      this.map.inStock = map.inStock;
      this.editMapId = map.id;
      this.$refs['map-form-modal'].show();
    },
    triggerDeleteMap (map) {
      this.deleteMap(map.id)
        .then((response) => {
          if(response === 204)
          {
            this.notif('success','Carte supprimée');
          }else{
            this.notif('warning','Erreur lors de la suppression');
          }
        })
        .catch(()=>{
          this.notif('warning','Erreur lors de la suppression');
        })
    },
    triggerAddMap () {
      this.$refs['map-form-modal'].show();
    },
    triggerSubmitForm () {
      if(this.editEnable){
        this.updateMap({'map':this.map,'mapId':this.editMapId})
          .then((response) => {
            if(response === 200){
              this.notif('success', 'Carte supprimé');
              this.completeForm();
            }else{
              this.notif('warning', 'Erreur lors de la mise à jour');
            }
          })
        .catch(()=>{
          this.notif('warning', 'Erreur lors de la mise à jour');
        });
      }else{
        this.addMap(this.map)
          .then((response)=>{
            if(response === 201){
              this.notif('success', 'Carte ajouté');
              this.completeForm()
            }else{
              this.notif('warning', 'Erreur lors de l\'ajout de la carte1');
            }
          })
          .catch(()=>this.notif('warning', 'Erreur lors de l\'ajout de la carte2'));
      }
    },
    modalHiddenAction () {
      this.clearForm();
    },
    clearForm () {
      this.map.reference = '';
      this.map.name = '';
      this.map.inStock = false;
      this.editEnable = false;
      this.editMapId = 0;
    },
    completeForm(){
      this.$refs['map-form-modal'].hide();
      this.clearForm();
    },
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
  }
};
</script>
<style>

</style>
